import FqApi from '@/services/fq-api'

const baseUrl = '/estimage'

export default {
  async validateQuote(object) {
    const url = `${baseUrl}/validateQuote`
    const result = await FqApi.post(url, object)
    return result
  },
  async sendQuote(object) {
    const url = `${baseUrl}/submitQuote`
    const result = await FqApi.post(url, object)
    return result
  },
  async updateVehicle(object) {
    const url = `${baseUrl}/updateVehicle`
    const result = await FqApi.post(url, object)
    return result
  },
  async uploadImages(object) {
    const url = `${baseUrl}/uploadImages`
    const result = await FqApi.post(url, object)
    return result
  },
  async sendInvoice(object) {
    const url = `${baseUrl}/submitInvoice`
    const result = await FqApi.post(url, object)
    return result
  },
  async getEstimageConfig() {
    const url = `${baseUrl}/getEstimageConfig`
    const result = await FqApi.get(url)
    return result.data
  },
  async subscribe() {
    const url = `${baseUrl}/subscribe`
    const result = FqApi.post(url)
    return result
  }
}
