export default {
  bind(el, binding) {
    el.addEventListener('keydown', function (e) {

      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {

        // get a child with class dropdown-menu
        const autoCompleteField = el.querySelector('.dropdown-menu')

        // check if autocomplete field is visible, dont do anything
        if(autoCompleteField && (autoCompleteField.style.display == '' || autoCompleteField.style.display == null)) return

        e.preventDefault()

        // // Find the parent element (e.g., '.quote-builder-item-row')
        const parent = el.closest('.quote-builder-item-row')
        const id = parent.id
        if(!id) return

        const elements = document.querySelectorAll('.quote-builder-item-row:not(.strike-through-line):not(.is-hidden)')

        const elementsArray = Array.from(elements)
        const currentIndex = elementsArray.findIndex((el) => el.id == id)

        let nextIndex
        if (e.key === 'ArrowDown') {
          // if current index is the last element, set nextIndex to 0
          if (currentIndex === elementsArray.length - 1) {
            nextIndex = 0
          } else {
            nextIndex = currentIndex + 1
          }
        } else if (e.key === 'ArrowUp') {

          // if current index is the first element, set nextIndex to the last element
          if (currentIndex === 0) {
            nextIndex = elementsArray.length - 1
          }else{
             nextIndex = currentIndex - 1
            }
        }

       // get the field id for the current element and use it to focus the next element
        const fieldId = el.id.split('-').pop()

        //if next idex is

         if(nextIndex < 0 || nextIndex >= elementsArray.length) return

        // get id for nextIndex element
        const nextElementId = elementsArray[nextIndex].id

        if (binding.value && typeof binding.value === 'function') {
          binding.value(nextElementId, fieldId)
        }

      }
    })
  }
}