/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.OrmFilter = (function() {

    /**
     * Namespace OrmFilter.
     * @exports OrmFilter
     * @namespace
     */
    var OrmFilter = {};

    OrmFilter.OrmFilterModel = (function() {

        /**
         * Properties of an OrmFilterModel.
         * @memberof OrmFilter
         * @interface IOrmFilterModel
         * @property {string|null} [rego] OrmFilterModel rego
         * @property {string|null} [quoteNo] OrmFilterModel quoteNo
         * @property {string|null} [claimNo] OrmFilterModel claimNo
         * @property {number|null} [messageTypeId] OrmFilterModel messageTypeId
         * @property {number|null} [messageStatusId] OrmFilterModel messageStatusId
         * @property {string|null} [sortColumn] OrmFilterModel sortColumn
         * @property {string|null} [sortOrder] OrmFilterModel sortOrder
         * @property {number|null} [pageIndex] OrmFilterModel pageIndex
         * @property {number|null} [pageSize] OrmFilterModel pageSize
         * @property {string|null} [customerNameOrContactNo] OrmFilterModel customerNameOrContactNo
         */

        /**
         * Constructs a new OrmFilterModel.
         * @memberof OrmFilter
         * @classdesc Represents an OrmFilterModel.
         * @implements IOrmFilterModel
         * @constructor
         * @param {OrmFilter.IOrmFilterModel=} [p] Properties to set
         */
        function OrmFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OrmFilterModel rego.
         * @member {string|null|undefined} rego
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.rego = null;

        /**
         * OrmFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.quoteNo = null;

        /**
         * OrmFilterModel claimNo.
         * @member {string|null|undefined} claimNo
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.claimNo = null;

        /**
         * OrmFilterModel messageTypeId.
         * @member {number|null|undefined} messageTypeId
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.messageTypeId = null;

        /**
         * OrmFilterModel messageStatusId.
         * @member {number|null|undefined} messageStatusId
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.messageStatusId = null;

        /**
         * OrmFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.sortColumn = null;

        /**
         * OrmFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.sortOrder = null;

        /**
         * OrmFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.pageIndex = null;

        /**
         * OrmFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.pageSize = null;

        /**
         * OrmFilterModel customerNameOrContactNo.
         * @member {string|null|undefined} customerNameOrContactNo
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        OrmFilterModel.prototype.customerNameOrContactNo = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * OrmFilterModel _rego.
         * @member {"rego"|undefined} _rego
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_rego", {
            get: $util.oneOfGetter($oneOfFields = ["rego"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _claimNo.
         * @member {"claimNo"|undefined} _claimNo
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_claimNo", {
            get: $util.oneOfGetter($oneOfFields = ["claimNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _messageTypeId.
         * @member {"messageTypeId"|undefined} _messageTypeId
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_messageTypeId", {
            get: $util.oneOfGetter($oneOfFields = ["messageTypeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _messageStatusId.
         * @member {"messageStatusId"|undefined} _messageStatusId
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_messageStatusId", {
            get: $util.oneOfGetter($oneOfFields = ["messageStatusId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmFilterModel _customerNameOrContactNo.
         * @member {"customerNameOrContactNo"|undefined} _customerNameOrContactNo
         * @memberof OrmFilter.OrmFilterModel
         * @instance
         */
        Object.defineProperty(OrmFilterModel.prototype, "_customerNameOrContactNo", {
            get: $util.oneOfGetter($oneOfFields = ["customerNameOrContactNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified OrmFilterModel message. Does not implicitly {@link OrmFilter.OrmFilterModel.verify|verify} messages.
         * @function encode
         * @memberof OrmFilter.OrmFilterModel
         * @static
         * @param {OrmFilter.IOrmFilterModel} m OrmFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrmFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.rego != null && Object.hasOwnProperty.call(m, "rego"))
                w.uint32(10).string(m.rego);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(18).string(m.quoteNo);
            if (m.claimNo != null && Object.hasOwnProperty.call(m, "claimNo"))
                w.uint32(26).string(m.claimNo);
            if (m.messageTypeId != null && Object.hasOwnProperty.call(m, "messageTypeId"))
                w.uint32(32).int32(m.messageTypeId);
            if (m.messageStatusId != null && Object.hasOwnProperty.call(m, "messageStatusId"))
                w.uint32(40).int32(m.messageStatusId);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(50).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(58).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(72).int32(m.pageSize);
            if (m.customerNameOrContactNo != null && Object.hasOwnProperty.call(m, "customerNameOrContactNo"))
                w.uint32(82).string(m.customerNameOrContactNo);
            return w;
        };

        /**
         * Encodes the specified OrmFilterModel message, length delimited. Does not implicitly {@link OrmFilter.OrmFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OrmFilter.OrmFilterModel
         * @static
         * @param {OrmFilter.IOrmFilterModel} message OrmFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrmFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrmFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof OrmFilter.OrmFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {OrmFilter.OrmFilterModel} OrmFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrmFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.OrmFilter.OrmFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.rego = r.string();
                    break;
                case 2:
                    m.quoteNo = r.string();
                    break;
                case 3:
                    m.claimNo = r.string();
                    break;
                case 4:
                    m.messageTypeId = r.int32();
                    break;
                case 5:
                    m.messageStatusId = r.int32();
                    break;
                case 6:
                    m.sortColumn = r.string();
                    break;
                case 7:
                    m.sortOrder = r.string();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                case 9:
                    m.pageSize = r.int32();
                    break;
                case 10:
                    m.customerNameOrContactNo = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an OrmFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OrmFilter.OrmFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OrmFilter.OrmFilterModel} OrmFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrmFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return OrmFilterModel;
    })();

    return OrmFilter;
})();

module.exports = $root;
